import * as Sentry from "@sentry/browser";
var pjson = require("../../package.json");

function init() {
  console.log("Environment:", process.env.NODE_ENV);
  if (process.env.NODE_ENV === "production")
    Sentry.init({
      dsn: "https://2afd9f37da35ace9fb8eb1048f5f17ca@o319767.ingest.us.sentry.io/4508297070641152",
      release: `dashboard@${pjson.version}`,

      tracesSampleRate: 0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

      integrations: [
        Sentry.replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true,
        }),
        Sentry.replayCanvasIntegration(),
      ],
    });
  // not working on current version
  // Sentry.configureScope(function (scope) {
  //   // scope.setTag("my-tag", "my value");
  //   scope.setUser({
  //     username: getCurrentUser()?.name,
  //   });
  // });
}

function error(message, errObj = null) {
  if (errObj) Sentry.captureException(errObj);
  else Sentry.captureMessage(message);
  console.error(message, errObj);
}

function log(message) {
  const stringMessage = typeof message !== "string" ? JSON.stringify(message) : message;
  Sentry.captureMessage(stringMessage, "info");
  console.log(message);
}

function info(message) {
  const stringMessage = typeof message !== "string" ? JSON.stringify(message) : message;
  Sentry.captureMessage(stringMessage, "info");
  console.info(message);
}

function warning(message) {
  Sentry.captureMessage(message, "warning");
  console.warn(message);
}

export const logService = {
  init,
  log,
  error,
  warning,
  info,
};
export default logService;
