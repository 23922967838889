import React from "react";

export async function route(data, history) {
  const type = barcodeLogic(data);
  switch (type) {
    case "return":
      history.push(`/refurbish/${data}`);
      break;
    case "location":
      history.push(`/warehouse/location/${data}`);
      break;
    case "storageunit":
      history.push(`/warehouse/storageunit/${data}`);
      break;
    case "storagegroup":
      history.push(`/warehouse/storagegroup/${data}`);
      break;
    case "product":
      history.push(`/warehouse/product/${data}`);
      break;
    default:
      return { type: barcodeLogic(data), data };
  }
  return { type: barcodeLogic(data), data };
}

export async function getData(data) {
  try {
    const { s: scannedCode, /*v: version*/ } = JSON.parse(data);
    // TODO: switch to the upper line as soon as versioning has been deployed to the warehouse software.
    // if (scannedCode) return { type: "product", data: { scannedCode, ...(version ? { version } : {}) } }; 
    if (scannedCode) {
      console.log({ type: "product", data: scannedCode });
      return { type: "product", data: scannedCode };
    }
  } catch (e) {
    // console.log("Not a JSON object");
  }
  const type = barcodeLogic(data);

  // TODO: uncomment to the upper line as soon as versioning has been deployed to the warehouse software.
  // if (type === "product") return { type, data: { scannedCode: data } }; else
  return { type, data };
}

function barcodeLogic(data) {

  try {
    const dataObj = JSON.parse(data);
    if (dataObj.s && dataObj.v) return "product";
  } catch (e) {
    // console.log("Not a JSON object");
  }

  if (data.length === 6) return "return";
  else if (data.length === 16 && data.substring(0, 3) === "LOC") return "location";
  else if (data.length === 9 && data.substring(0, 3) === "STU") return "storageunit";
  else if (data.length === 9 && data.substring(0, 3) === "GRP") return "storagegroup";
  else if (data.length === 9 && data.substring(0, 3) === "RT-") return "return";
  else return "product";
}

const barcodeService = { route, getData, barcodeLogic };

export const BarcodeContext = React.createContext({});
export const BarcodeProvider = BarcodeContext.Provider;

export default barcodeService;
