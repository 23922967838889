import http from "./httpService";
import { getCategoriesFromUser } from "./userService";
import { BillbeeSearchResults, SearchResultsOrder } from "billbee-types";

const apiUrl = process.env.REACT_APP_API_URL;

export async function getSearchResults(query: string) {
  const endpointName = "billbee/search/orders";

  //"+Vorname*  +Nachname* -tags:billing"
  let technicalQuery = query
    .split(" ")
    .map(e => `+${e}*`)
    .concat("-tags:Shipping")
    .join(" ");

  let userRoleLimits =
    "(" +
    getCategoriesFromUser()
      .map(c => `+tags:"${c}"`)
      .join(" OR ") +
    ")";

  if (userRoleLimits === "()") userRoleLimits = "";

  const result = await http.get<BillbeeSearchResults>(
    apiUrl + endpointName + "?searchQuery=" + technicalQuery + userRoleLimits
  );

  if (!result || !result.data || !result.data.Orders) return [];
  //Filter order states 6 and 8 (deleted and canceled) + filter orders with order number containing "-GS" or "-S-" (or legacy "-Shipping-")
  const filteredOrders = result.data.Orders.filter(o => {
    return o.ExternalReference
      ? !o.ExternalReference.includes("-GS") &&
          !o.ExternalReference.includes("-Shipping-") &&
          !o.ExternalReference.includes("-S-") &&
          !o.ExternalReference.includes("Ersatz")
      : true;
  });

  return mapFields(filteredOrders);
}

// export async function getSearchResults(query: string) {
//   const searchEndpoint = "orders/search";

//   let searchBody = {
//     term: query,
//   };

//   const searchResult = await http.post(apiUrl + searchEndpoint, searchBody);
//   let validResults: SearchResultsOrder[] = [];

//   if (searchResult.data.data) {
//     const orderNumbers = searchResult.data.data.map((order: any) => order);

//     const searchPromises = orderNumbers.map(async (num: string) => {
//       const result = await http.get<BillbeeSearchResults>(apiUrl + "billbee/search/orders?searchQuery=" + `+"${num}"`);
//       return result.data?.Orders?.[0];
//     });

//     const results = await Promise.all(searchPromises);

//     validResults = results.filter((order): order is SearchResultsOrder => !!order);
//   }

//   const endpointName = "billbee/search/orders";
//   let technicalQuery = query
//     .split(" ")
//     .map(e => `+${e}*`)
//     .concat("-tags:Shipping")
//     .join(" ");

//   let userRoleLimits =
//     "(" +
//     getCategoriesFromUser()
//       .map(c => `+tags:"${c}"`)
//       .join(" OR ") +
//     ")";

//   if (userRoleLimits === "()") userRoleLimits = "";

//   const secondSearchResult = await http.get<BillbeeSearchResults>(
//     apiUrl + endpointName + "?searchQuery=" + technicalQuery + userRoleLimits
//   );

//   if (secondSearchResult?.data?.Orders) {
//     const additionalOrders = secondSearchResult.data.Orders.filter(order => {
//       // print all existing order ids
//       console.log(
//         "Existing Order IDs:",
//         validResults.map(order => order.Id)
//       );
//       console.log("Current Order ID:", order.Id);

//       const isDuplicate = validResults.some(existingOrder => existingOrder.Id === order.Id);
//       console.log("Is Duplicate:", isDuplicate);
//       const isUnwantedOrder = order.ExternalReference
//         ? order.ExternalReference.includes("-GS") ||
//           order.ExternalReference.includes("-Shipping-") ||
//           order.ExternalReference.includes("-S-") ||
//           order.ExternalReference.includes("Ersatz")
//         : false;

//       console.log(isUnwantedOrder);

//       return !isDuplicate && !isUnwantedOrder;
//     });

//     validResults = [...validResults, ...additionalOrders];
//   }

//   return mapFields(validResults);
// }

export async function getOrderDetails(
  orderId: string,
  maxCacheAge = undefined,
  overrideFragment: Record<string, unknown> | undefined = undefined
) {
  const endpointName = "beestone/orders";

  // Define the desired return fields
  const fragment = {
    BillBeeOrderId: true,
    BillbeeId: true,
    OrderNumber: true,
    SellerComment: true,
    State: true,
    OrderItems: {
      Quantity: true,
      Product: {
        SKU: true,
        BillbeeId: true,
        Title: {
          LanguageCode: true,
          Text: true,
        },
      },
    },
    CreatedAt: true,
    ShippingAddress: {
      FirstName: true,
      LastName: true,
      Company: true,
      Street: true,
      Housenumber: true,
      Zip: true,
      City: true,
    },
    cacheUpdatedAt: true,
  };
  // Only set maxCacheAge if it is defined in the incoming request to optimize performance
  const orderData = await http.get(
    `${apiUrl}${endpointName}/${orderId}?fragmentJson=${JSON.stringify(overrideFragment || fragment)}${
      maxCacheAge || maxCacheAge === 0 ? `&maxCacheAge=${maxCacheAge}` : ""
    }`
  );
  return orderData;
}

function mapFields(resultArray: SearchResultsOrder[]) {
  const mappedArray = resultArray.map(({ CustomerName, ExternalReference, Id }) => {
    return {
      name: CustomerName,
      type: "searchResult",
      falseDelivery: false,
      orderNumber: ExternalReference,
      productName: "",
      quantity: "",
      sku: "",
      orderId: Id,
    };
  });
  return mappedArray;
}

const ordersService = {
  getSearchResults,
  getOrderDetails,
};

export default ordersService;
