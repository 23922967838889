import http from "./httpService";
import utils from "../utils/dateUtils";
import asyncUtils from "../utils/asyncUtils";
import logger from "./logService";
import _ from "lodash";
import { toast } from "react-toastify";

const apiUrl = process.env.REACT_APP_API_URL;

//Get a list of products
export async function getAllProducts() {
  //get cached products
  const cachedProductsJSON = sessionStorage.getItem("products");

  if (cachedProductsJSON && cachedProductsJSON !== "{}") return JSON.parse(cachedProductsJSON);

  const endpointName = "products";
  const result = await http.get(apiUrl + endpointName);
  if (!result) {
    toast.error("Produkte konnten nicht geladen werden");
    return;
  }

  sessionStorage.setItem("products", JSON.stringify(result.data));
  return result.data;
}

export async function getProductsList(populateParentProduct = false) {
  try {
    const { data } = await http.get(`${apiUrl}products${populateParentProduct ? "?populateParentProduct=true" : ""}`);
    return data;
  } catch (err) {
    toast.error("Fehler beim laden der Produktliste.");
    logger.error("Error loading the product list: ", err);
  }
}

//Get all Products of a specific category
export async function getProductsOfCategory(category) {
  const products = await getAllProducts();
  const result = products.map(p => p.Category1.Name === category);
  return result;
}

export function getFirstWithCategoryPreloaded(products, category) {
  const matchingProduct = products.find(p => {
    try {
      return p.Category1.Name === category;
    } catch (err) {
      return false;
    }
  });
  return matchingProduct;
}

export function getProductsOfCategoryPreloaded(products, category) {
  const result = products.map(p => p.Category1.Name === category);
  return result;
}

//Products Search
export async function getSearchResults(query) {
  const endpointName = "billbee/search/products";

  const technicalQuery = query
    .split(" ")
    .map(e => `+${e}*`)
    .join(" ");

  const result = await http.get(apiUrl + endpointName + "?searchQuery=" + technicalQuery);
  if (!result || !result.data || !result.data.Orders) return [];
  const filteredProducts = result.data.Products.filter(p =>
    p.SKU ? p.SKU.substr(p.SKU.length - 2, 2) !== "-U" : true
  );
  return mapFields(filteredProducts);
}

function mapFields(resultArray) {
  const mappedArray = resultArray.map(({ SKU }) => {
    return SKU;
  });
  return mappedArray;
}

//trigger quick refresh of data
export async function refreshAnalysisData(deep = false) {
  const timeStamp = Date.now();
  await http.post(apiUrl + `products/update?timestamp=${timeStamp}` + (deep ? `&deepUpdate=true&queryDays=1` : ""));
  for (let i = 0; i < 4000; i++) {
    await asyncUtils.timeout(1000);
    //Is it ready?
    const { data } = await http.get(apiUrl + `products/update?timestamp=${timeStamp}`);
    if (data && data[timeStamp] === "Completed") return true;
    if (data && data[timeStamp] === "Failed") return false;
    if (!data[timeStamp]) return false;
  }
}

//Get Product Analysis data from formbench database
export async function getProductAnalysisList(days = 60, hideBstock = false, hideBundles = true) {
  const endDate = new Date();
  endDate.setUTCHours(-2, 0, 0, 0);
  const startDate = utils.addDays(new Date(), -days);
  startDate.setUTCHours(-2, 0, 0, 0);
  const query = `products/list?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&excludeBStock=${hideBstock}&excludeBundles=${hideBundles}&populateParentProduct=true`;
  const { data: productList } = await http.get(apiUrl + query);
  // Gets catched if not an array or empty
  if (!Array.isArray(productList) || productList.length === 0) throw new Error("Could not load product list");
  const restructuredProductList = productList.map(p => {
    const mergedChartData = [];
    if (p.history && p.history.stockLevels) {
      for (const s of p.history.stockLevels) {
        const dataPoint = {};
        dataPoint.date = s.date;
        dataPoint.stockLevels = s.quantity;
        Object.assign(
          dataPoint,
          p.history.sales ? _.find(p.history.sales, { date: s.date }) : { quantity: 0, revenue: 0, tax: 0 }
        );
        dataPoint.stockLevelsFba = p.history.stockLevelsFba
          ? _.find(p.history.stockLevelsFba, { date: s.date })?.quantity
          : null;
        mergedChartData.push(dataPoint);
      }
    }
    p.mergedChartData = mergedChartData;
    return p;
  });

  return dateToNumeric(restructuredProductList);

  function dateToNumeric(object) {
    function transformObject(data) {
      if (Array.isArray(data)) return arrayProcedure(data);
      else if (typeof data === "object") return objectProcedure(data);
      else return data;
    }

    function transformValues(data, key) {
      if (key === "date") {
        const newVal = new Date(data[key]);

        //Accomodate for daylight savings time. Data is saved at 22:00 UTC -> 00:00 European Summer time.
        //In Winter, this is 23:00 the previous day, so to fix double days / day shift we add one hour so the date is correct.
        //This means, that stricly viewed, the data is offset by 1 hour. We ignore this here to make the implementation simpler.
        return newVal.getTime() + 3600000;
      } else return data[key];
    }

    function arrayProcedure(data) {
      let sortedData = [...data];
      try {
        sortedData = _.sortBy(data, ["date"]);
      } catch (err) {
        logger.info("could not sort");
      }
      return sortedData.map(d => transformObject(d));
    }

    function objectProcedure(data) {
      const newData = {};
      for (const key in data) {
        if (typeof data[key] === "object") newData[key] = transformObject(data[key]);
        else newData[key] = transformValues(data, key);
      }
      return newData;
    }

    return transformObject(object);
  }
}

export function groupList(list) {
  const groupDict = {};
  const groupDataDict = {};

  list.forEach((i, j) => {
    const parentProduct = _.get(i, "parentProduct") || { _id: "unassigned" };
    groupDict[parentProduct._id] ? groupDict[parentProduct._id].push(i) : (groupDict[parentProduct._id] = [i]);
    groupDataDict[parentProduct._id] = parentProduct;
  });

  const groupedArray = Object.keys(groupDict).map(_id => {
    return Object.assign(
      { data: groupDict[_id], groupData: getChartData(groupDict[_id]) },
      { _id, name: groupDataDict[_id]?.name || "" }
    );
  });

  return groupedArray;

  function getChartData(groupContent) {
    const remappedData = [];
    const tempDataObject = {};
    for (const item of groupContent) {
      const sales = item.history?.sales;
      const stockLevels = item.history?.stockLevels;
      const stockLevelsFba = item.history?.stockLevelsFba;
      if (sales && Object.keys(sales).length)
        sales.forEach((s, i) => {
          if (tempDataObject[s.date]) {
            //tempDataObject[s.date][item.name] = s.quantity;
            tempDataObject[s.date].quantity += s.quantity;
            tempDataObject[s.date].revenue += s.revenue;
            tempDataObject[s.date].tax += s.tax;
            tempDataObject[s.date].stockLevels += stockLevels[i]?.quantity || 0;
            tempDataObject[s.date].stockLevelsFba += stockLevelsFba[i]?.quantity || 0;
          } else
            tempDataObject[s.date] = {
              //[item.name]: s.quantity,
              date: s.date,
              quantity: s.quantity,
              tax: s.tax,
              revenue: s.revenue,
              stockLevels: stockLevels[i]?.quantity || 0,
              stockLevelsFba: stockLevelsFba[i]?.quantity || 0,
            };
        });
    }

    for (const key in tempDataObject) {
      remappedData.push(tempDataObject[key]);
    }

    return remappedData;
  }
}

export async function getInventory() {
  const { data } = await http.get(apiUrl + "inventory");
  return data;
}

export async function saveLeadTime(productId, newValue) {
  const body = { leadTime: newValue };
  await http.put(apiUrl + `products/${productId}`, body);
}

export async function saveRestockTime(productId, newValue) {
  const body = { restockTime: newValue };
  await http.put(apiUrl + `products/${productId}`, body);
}

export async function updateProduct(sku, body) {
  await http.put(apiUrl + `products/${encodeURIComponent(sku)}`, body);
}

const productsService = {
  getAllProducts,
  getProductsList,
  getProductsOfCategory,
  groupList,
  getProductsOfCategoryPreloaded,
  getFirstWithCategoryPreloaded,
  getSearchResults,
  getProductAnalysisList,
  refreshAnalysisData,
  getInventory,
  saveLeadTime,
  saveRestockTime,
  updateProduct,
};

export default productsService;
