import React from "react";
import moment from "moment-timezone";

const Task = ({ id, title, description, attachments, due, completed, onComplete, onOpenAttachment, children }) => {
  function getRelevanceColor(dateString) {
    const date = new Date(dateString);
    const today = new Date();
    return date.getTime() >= today.getTime() ? "text-success" : "text-danger";
  }

  function getNiceDate(dateString) {
    return dateString ? moment(dateString).tz("Europe/Berlin").format("DD. MMM YYYY") : "";
  }

  function createMarkup(content) {
    return { __html: content };
  }

  return (
    <div className="row my-3">
      <div className={completed ? "text-muted col-12 col-md" : "col"} style={completed ? { opacity: "0.4" } : {}}>
        <div className="form-row">
          <div className="col-auto">
            <i
              className={
                completed
                  ? "fas fa-check-circle text-completed cursor-hand mr-2"
                  : "far fa-check-circle cursor-hand mr-2"
              }
              style={{ fontSize: "28px" }}
              onClick={() => onComplete(id)}
            ></i>
          </div>
          <div className="col align-self-top paddingTaskTitle">
            <h5>{title}</h5>
            {description && <div dangerouslySetInnerHTML={createMarkup(description)}></div>}
            {attachments && attachments.length
              ? attachments.map(a => (
                  <div
                    key={a.gid}
                    className="badge badge-pill badge-secondary badge-break mb-1 mr-1 "
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={() => onOpenAttachment(a.gid)}
                  >
                    <div className="form-row">
                      <div className="col-auto">
                        <i className="fa fa-paperclip ml-1" aria-hidden="true"></i>
                      </div>
                      <div className="col mr-2">{a.name}</div>
                    </div>
                  </div>
                ))
              : ""}
            {children}
          </div>
        </div>
      </div>
      <div className="col-12 col-md-auto align-self-top text-right">
        <span className={`my-auto ${completed ? "text-muted" : getRelevanceColor(due)}`}>{getNiceDate(due)}</span>
      </div>
    </div>
  );
};

export default Task;
