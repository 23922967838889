import { formatDate } from "../../utils/dateUtils";
import _ from "lodash";
import { Timeline, TimelineEvent } from "react-event-timeline";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { ShipmentsTimelineEntry } from "./ShipmentsTimelineEntry";

const UpcomingShipmentsTimeline = ({ shipments }) => {
  const getContent = item => {
    return <ShipmentsTimelineEntry item={item} />;
  };

  const getReceiveStatus = item =>
    item.receiveDate ? <i className="fas fa-truck-loading"></i> : <i className="fas fa-truck"></i>;

  function getRowColor(shipment) {
    if (shipment.shipmentType === "outbound") return "#FFAB00";
    if (shipment.receiveCompletedDate) return "#8BD86C";
    if (shipment.receiveDate) return "#FFC22C";
    return "#523D3D";
  }

  function getDateString(shipment) {
    return shipment.receiveDate
      ? `Angenommen am ${formatDate(shipment.receiveDate, false)}`
      : `Versand am ${formatDate(shipment.shippingDate, false)}`;
  }

  const sorted = _.orderBy(shipments, ["receiveDate", "shippingDate"], "asc");

  return (
    <div>
      <Timeline>
        {sorted.map(f => (
          <TimelineEvent
            key={f.shipmentId}
            title=""
            createdAt={getDateString(f)}
            icon={getReceiveStatus(f)}
            iconColor={getRowColor(f)}
            iconStyle={{ marginLeft: "2px" }}
            contentStyle={{ background: "transparent", boxShadow: "none", padding: "0px" }}
            titleStyle={{ fontSize: "20px" }}
          >
            {getContent(f)}
          </TimelineEvent>
        ))}
      </Timeline>
      <br />
      <Link to={`/warehouse`}>
        <Button variant="outline-secondary">Zur Lagerübersicht</Button>
      </Link>
    </div>
  );
};

//ZUM INSTALLIEREN DER LIBRARY: npm i react-event-timeline

export default UpcomingShipmentsTimeline;
