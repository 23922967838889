import React, { useState, useEffect } from "react";
import Task from "./Task";
import tasksService, { toggleTaskCompletion } from "../../services/tasksService";
import { ShipmentsTimelineEntry } from "../upcomingShipments/ShipmentsTimelineEntry";

const Tasks = ({ tasks, setTasks, loadTasks, shipments }) => {
  const [tasksSaved, setTasksSaved] = useState(Date.now());

  //Get tasks whenever a change was made
  useEffect(() => {
    loadTasks(false);
  }, [tasksSaved, loadTasks]);

  async function handleClick(gid) {
    const tasksCopy = [...tasks];
    const tasksBackup = [...tasks];
    const task = tasksCopy.find(t => t.gid === gid);
    task.completed = !task.completed;
    setTasks(tasksCopy);
    try {
      await toggleTaskCompletion(gid, task.completed);
    } catch (err) {
      setTasks(tasksBackup);
    }
    setTasksSaved(Date.now());
  }

  async function handleOpenAttachment(gid) {
    const attachment = await tasksService.getAttachment(gid);
    window.open(attachment.download_url, "_blank");
  }

  function getRowColor(shipment) {
    if (shipment.shipmentType === "outbound") return "#FFAB00";
    if (shipment.receiveCompletedDate) return "#8BD86C";
    if (shipment.receiveDate) return "#FFC22C";
    return "#523D3D";
  }

  return (
    <div className="container-fluid">
      {tasks.length ? (
        tasks.map(task => {
          const shipment = shipments.find(
            s =>
              s.shipmentId?.toLowerCase() ===
              task.custom_fields.find(cf => cf.gid === "1208909685402444").text_value?.toLowerCase()
          );
          return (
            <div key={task.gid} className="row justify-content-md-center">
              <div className="col-12">
                <div>
                  <Task
                    id={task.gid}
                    title={task.name}
                    description={task.html_notes}
                    attachments={task.attachments}
                    due={task.due_on}
                    completed={task.completed}
                    onComplete={handleClick}
                    onOpenAttachment={handleOpenAttachment}
                  >
                    {shipment ? (
                      <div
                        style={{
                          padding: "10px",
                          borderLeft: `solid 3px ${getRowColor(shipment)}`,
                          marginTop: "10px",
                        }}
                      >
                        <ShipmentsTimelineEntry item={shipment} />
                      </div>
                    ) : (
                      ""
                    )}
                  </Task>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="h3 text-muted text-center">Keine offenen Aufgaben</div>
      )}
    </div>
  );
};

export default Tasks;
