import React, { Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "../common/Form";
import Loader from "../common/Loader";
import Checkbox from "../common/Checkbox";
import SimplePromiseModal from "../common/SimplePromiseModal";
import Joi from "joi-browser";
import { createModal } from "react-modal-promise";
import { isSafeToClick } from "../../utils/helper";

const areYouSureModal = createModal(SimplePromiseModal);

class ReceiveModal extends Form {
  state = {
    data: {
      amount: 1,
      notes: "",
      refundDeduction: 0,
      refundRequired: true,
    },
    prevShow: false,
    inputNotes: "",
    expectedAmount: 0,
    receivedAmount: 0,
    errors: {},
  };

  schema = {
    amount: Joi.number()
      .min(1)
      .required()
      .label("Menge")
      .error(errors => {
        errors.forEach(err => {
          err.message = "Bitte geben Sie eine gültige Menge an.";
        });
        return errors;
      }),
    notes: Joi.string().allow("").label("Anmerkungen"),
    refundDeduction: Joi.number().min(0).label("Abzug von der Erstattung"),
    refundRequired: Joi.bool().label("Erstatten"),
  };

  handleChange = ({ currentTarget: input }) => {
    const data = { ...this.state.data };
    data[input.name] = input.value;
    this.setState({ data });
  };

  componentDidUpdate() {
    if (this.props.show === true && this.state.prevShow === false) {
      this.setState({ prevShow: true });
      this.onShow();
    } else if (this.props.show === false && this.state.prevShow === true) {
      this.setState({ prevShow: false });
      this.onHide();
    }
  }

  onShow = () => {
    const { preannouncedItems, receivedItems } = this.props;

    const expectedAmount = preannouncedItems ? preannouncedItems.reduce((prev, cur) => prev + cur.quantity, 0) || 1 : 0;

    const receivedAmount = receivedItems?.reduce((prev, cur) => prev + cur.product.count, 0) || 0;
    this.setState({
      data: {
        ...this.state.data,
        amount: expectedAmount,
      },
      expectedAmount,
      receivedAmount,
      inputNotes: preannouncedItems?.map(p => p.notes).join(", ") || "",
    });
  };

  onHide = () => {
    this.props.onHide();
    setTimeout(this.resetState, 500);
  };

  handlePreannounce = async () => {
    if (this.state.expectedAmount > 0) {
      const confirmation = await areYouSureModal({
        confirmText: "Weitere Rücksendung ankündigen",
        description:
          "Es wurde bereits eine Rücksendung dieses Artikels angekündigt, bist du sicher, dass du eine weitere Rücksendung ankündigen möchtest?",
        title: "Ankündigung schon vorhanden.",
      });
      if (!confirmation) return;
    }
    await this.props.onPreannounce(this.state.data);
    setTimeout(this.resetState, 500);
  };

  renderInput = (name, label, placeholder, type = "text", readOnly = false, value, min = 0) => {
    const { data, errors } = this.state;
    return (
      <div className="form-group">
        <label htmlFor={name}>{label}</label>
        <input
          type={type}
          name={name}
          id={name}
          value={value !== undefined ? value : data[name]}
          onChange={this.handleChange}
          className="form-control"
          placeholder={placeholder}
          readOnly={readOnly}
          min={min}
        />
        {errors[name] && <div className="alert alert-danger">{errors[name]}</div>}
      </div>
    );
  };

  doSubmit = async () => {
    const submitData = {
      ...this.state.data,
      amount: Number(this.state.data.amount) <= 1 ? 1 : Number(this.state.data.amount),
    };
    await this.props.onSubmit(submitData);
    setTimeout(this.resetState, 500);
  };

  resetState = () => {
    this.setState({
      data: {
        amount: 1,
        notes: "",
        refundDeduction: 0,
        refundRequired: true,
      },
      inputNotes: "",
      expectedAmount: 0,
      receivedAmount: 0,
    });
    this.validate();
  };

  handleToggleRefundRequired = () => {
    const data = { ...this.state.data };
    data.refundRequired = !this.state.data.refundRequired;
    this.setState({ data });
  };

  userCanReceive() {
    return true;
  }

  userCanPreannounce() {
    const { user } = this.props;
    return user && user.roles && user.roles.indexOf("warehouse") === -1 && user.roles.indexOf("Brun") === -1;
  }

  render() {
    const {
      data: { amount, refundRequired },
      expectedAmount,
      receivedAmount,
      inputNotes,
    } = this.state;

    const { show, onHide, onSubmit, spinner } = this.props;

    const buttonText = `${amount} Rücksendung${amount > 1 ? "en" : ""} empfangen ${isSafeToClick(true)}`;

    return (
      <Modal
        show={show}
        onHide={onHide}
        onSubmit={onSubmit}
        backdrop="static"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Ware annehmen</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {spinner ? (
            <div className="m-5">
              <Loader />
            </div>
          ) : (
            <div>
              <h2 className={expectedAmount ? "text-success" : "text-black"}>{expectedAmount} Artikel erwartet</h2>
              <h3 className={receivedAmount ? "text-success mb-4" : "text-black mb-4"}>
                {receivedAmount} Artikel bereits angenommen
              </h3>
              {inputNotes && <p className="alert alert-warning">{inputNotes}</p>}
              {this.renderInput("amount", "Menge", "Bitte Menge eingeben", "number", false, amount, "1")}
              {this.renderTextarea(
                "notes",
                "",
                "Bitte dokumentiere den Zustand der Rücksendung",
                "text",
                this.state.data.notes
              )}
              {this.userCanPreannounce() ? (
                <Fragment>
                  {this.renderInput("refundDeduction", "Abzug von der Erstattung", "0", "number")}
                  <Checkbox
                    name="refundNeeded"
                    id="refundNeeded"
                    label="Rücksendung erstatten?"
                    isSelected={refundRequired}
                    onCheckboxChange={this.handleToggleRefundRequired}
                  />
                </Fragment>
              ) : (
                ""
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <div className="full-width mb-0">
            {this.userCanPreannounce() ? (
              <Button block variant="light" onClick={this.handlePreannounce}>
                Rücksendung ankündigen {isSafeToClick(true)}
              </Button>
            ) : (
              ""
            )}
          </div>
          <div className="full-width">
            <Button variant="secondary" className="mr-2" onClick={this.onHide}>
              Abbrechen
            </Button>
            {this.renderButton(
              buttonText,
              this.userCanReceive() && !spinner ? this.handleSubmit : () => {},
              this.userCanReceive() && !spinner ? "" : "disabled"
            )}
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ReceiveModal;
