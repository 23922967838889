import React from "react";
import ImageService from "../../services/imageService";
import Image from "../common/Image";
import Loader from "../common/Loader";
import { Link } from "react-router-dom";
import { formatDate } from "../../utils/dateUtils";
import { getBillbeeLocalizedString } from "../../utils/helper";

const ProductDetail = ({
  orderDetail,
  orderDetail: {
    CreatedAt: orderDate,
    ShippingAddress: {
      FirstName: firstName,
      LastName: lastName,
      Company: company,
      Street: street,
      HouseNumber: houseNumber,
      Zip: zipCode,
      City: city,
    },
    OrderItems: orderItems,
  },
  imageDb,
  buttons,
  receivedReturns,
  preannouncedReturns,
}) => {
  function getImageUrl(sku) {
    const thumbnailUrl = ImageService.getThumbUrl(sku, imageDb);
    return thumbnailUrl;
  }

  function mapProduct(orderItem) {
    const { Title: title, SKU: sku, EAN: ean, Weight: weight, BillbeeId: billbeeId } = orderItem.Product || {};
    return {
      title: title || "",
      sku: sku || "",
      ean: ean || "",
      weight: weight || "",
      billbeeId: billbeeId || "",
    };
  }

  return (
    <React.Fragment>
      <div className="row mb-4">
        <div className="col-4 text-right text-muted">
          Bestelldatum
          <br />
          {!lastName && "Name"}
          {!lastName && <br />}
          {lastName && "Name"}
          {lastName && <br />}
          {company && "Firma"}
          {company && <br />}
          {"Straße"}
          <br />
          {"Ort"}
        </div>
        <div className="col-8">
          {formatDate(orderDate, false)}
          <br />
          {firstName && firstName}
          {!lastName && <br />} {lastName && lastName}
          {lastName && <br />}
          {company && company}
          {company && <br />}
          {street} {houseNumber}
          <br />
          {zipCode} {city}
        </div>
      </div>
      {orderItems.map(orderItem => {
        const alreadyReceived = receivedReturns?.length
          ? receivedReturns.filter(r => r.product.sku === orderItem.Product?.SKU)
          : undefined;
        const preannounced = preannouncedReturns?.length
          ? preannouncedReturns.filter(p => p.sku === orderItem.Product?.SKU)
          : undefined;

        const isCanceled = orderDetail ? orderDetail.State === 8 || orderDetail.State === 6 : false;

        const expectedAmount = preannounced?.length || 0;
        const receivedAmount = alreadyReceived?.length || 0;
        const totalAmount = receivedAmount + expectedAmount;

        return (
          <div key={orderItem.Product?.BillbeeId + "-" + orderItem.Product?.SKU} className="row">
            <div className="col-4 mb-4">
              {imageDb.loading ? <Loader /> : <Image url={getImageUrl(orderItem.Product?.SKU)} />}
              {buttons.map(button => (
                <Link
                  style={{ fontSize: "14px" }}
                  key={button._id}
                  dangerouslySetInnerHTML={{
                    __html: isCanceled
                      ? "Storniert"
                      : receivedAmount
                      ? `${receivedAmount} von ${totalAmount} Erledigt`
                      : expectedAmount
                      ? `${expectedAmount} Erwartet`
                      : button.title,
                  }}
                  to="#"
                  onClick={() => {
                    const product = mapProduct(orderItem);
                    button.onClick(orderDetail, product, alreadyReceived, preannounced);
                  }}
                  className={
                    isCanceled
                      ? "btn btn-outline-secondary mt-4 btn-block"
                      : receivedAmount && !expectedAmount
                      ? "btn btn-success mt-4 btn-block"
                      : expectedAmount
                      ? "btn btn-outline-success mt-4 btn-block"
                      : "btn btn-outline-secondary mt-4 btn-block"
                  }
                ></Link>
              ))}
            </div>
            <div className="col-8 mb-4">
              <p>
                {orderItem.Quantity || 1}x {getBillbeeLocalizedString(orderItem.Product?.Title)}
              </p>
              <p>
                {orderItem.Attributes &&
                  orderItem.Attributes.map(({ Id: id, Name: name, Value: value }) => {
                    return (
                      <React.Fragment key={id}>
                        <span className="text-muted">{name}: </span>
                        {value}
                        <br />
                      </React.Fragment>
                    );
                  })}
              </p>
              <span className="text-muted text-monospace">SKU {orderItem.Product?.SKU}</span>
              <br />
              {orderItem.Product?.EAN && (
                <span className="text-muted text-monospace">EAN {orderItem.Product?.EAN}</span>
              )}
            </div>
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default ProductDetail;
