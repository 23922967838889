export const ShipmentsTimelineEntry = ({ item, ...rest }) => {
  const receivedCount = item.remainingStorageUnits.length
    ? item.contents.reduce((acc, red) => acc + red.count, 0) - item.remainingStorageUnits.length
    : undefined;

  return (
    <div {...rest}>
      <h5>{item.shipmentId}</h5>
      <div className="mb-3">
        {item.shipmentType === "outbound"
          ? ""
          : item.contents?.length
          ? item.contents.map(content =>
              content.count > 1
                ? `${receivedCount >= 0 ? `${receivedCount} von ` : ""}${content.count} ${
                    content.type === "pallet" ? "Paletten" : "Kartons"
                  }${receivedCount >= 0 ? " empfangen" : ""}`
                : `1 ${content.type === "pallet" ? "Palette" : "Karton"}`
            )
          : "⁣"}
      </div>
      {item.pdf && (
        <a href={item.pdf.webContentLink} target="_blank" className="btn btn-secondary" rel="noreferrer">
          <i className="fas fa-file-download mr-2"></i>STU-Labels
        </a>
      )}
    </div>
  );
};
