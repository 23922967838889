import { useEffect, useState } from "react";
import Tasks from "../tasks/Tasks";
import UpcomingShipmentsTimeline from "../upcomingShipments/UpcomingShipmentsTimeline";
import { loadAllShipments, loadStorageUnits } from "../../services/shipmentService";
import { addDays } from "../../utils/dateUtils";
import Loader from "../common/Loader";

const DashboardWarehouse = ({ setTasks, loadTasks, loading: loadingTasks, tasks, ...rest }) => {
  const [shipments, setShipments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  function filterShipments(shipments) {
    const filtered = shipments
      ? shipments.filter(s => {
          if (s.shipmentType === "outbound") {
            return false;
          }
          if (s.shipmentType === "inbound" && (!s.receiveDate || new Date(s.receiveDate) >= addDays(new Date(), -7))) {
            return true;
          }
          return false;
        })
      : shipments;

    return filtered;
  }

  async function fetchData() {
    const shipments = await loadAllShipments();
    const filteredShipments = filterShipments(shipments);
    setShipments(filteredShipments);
    setIsLoading(false);

    // Load storage units for each shipment asynchronously
    const results = await Promise.allSettled(filteredShipments.map(shipment => loadStorageUnits(shipment)));
    const stus = results.map(result => (result.status === "fulfilled" ? result.value : []));
    filteredShipments.forEach((s, i) => {
      const types = stus[i].map(stu => stu.category);
      const typeList = [...new Set(types)];
      s.contents = typeList.map(type => ({ type: type, count: stus[i].filter(stu => stu.category === type).length }));
    });
    setShipments(filteredShipments);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <div className="row mt-3">
        <div className="col-12 col-lg-4 order-2 order-lg-1">
          <div className="bg-light-gray px-2 px-lg-4 py-4 rounded">
            <h1 className="text-center mb-4">Lieferungen</h1>
            {isLoading ? <Loader /> : <UpcomingShipmentsTimeline shipments={shipments} />}
          </div>
        </div>
        <div className="col-12 col-lg-8 order-1 order-lg-2 mb-5">
          <div className="bg-light-gray py-4 px-2 px-lg-4 rounded">
            <h1 className="text-center mb-4">Aufgaben</h1>
            {loadingTasks ? (
              <Loader />
            ) : (
              <Tasks tasks={tasks} setTasks={setTasks} loadTasks={loadTasks} shipments={shipments} {...rest} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardWarehouse;
