import React, { useState, Fragment } from "react";
import Modal from "react-bootstrap/Modal";
import Loader from "../common/Loader";
import Button from "react-bootstrap/Button";
import warehouseService from "../../services/warehouseService";
import Select from "../common/Select";
import AddLocationModal from "./AddLocationModal";
import AddProductModal from "./AddProductModal";
import SimplePromiseModal from "../common/SimplePromiseModal";
import { createModal } from "react-modal-promise";
import { toast } from "react-toastify";
import logger from "../../services/logService";
import _ from "lodash";
import { isSafeToClick } from "../../utils/helper";

const LocationSettings = ({
  isOpen,
  onResolve,
  onReload,
  description,
  location,
  spinner,
  confirmText,
  cancelText,
  buttonColor,
}) => {
  const [data, setData] = useState(prepareUpdateArray());
  const [errorMessage, setErrorMessage] = useState("");

  const showConfirmModal = createModal(SimplePromiseModal);
  const showAddLocationModal = createModal(AddLocationModal);
  const showAddProductModal = createModal(AddProductModal);

  function prepareUpdateArray() {
    const body = {
      pickingSpot: location.pickingSpot,
      storageCategory: location.storageCategory,
    };
    if (location.contents && location.contents.length) body.contents = location.contents;
    if (location.connectedLocations) body.connectedLocations = location.connectedLocations;
    return body;
  }

  const options = [
    { name: "Picking-Platz", _id: "pickingSpot" },
    { name: "Picking-Platz (Gemischt)", _id: "pickingMultiple" },
    { name: "Nachfüllager", _id: "storage" },
    { name: "Block-Nachfüllager", _id: "storageMultiple" },
  ];

  const categoryOptions = [
    { name: "Palette", _id: "pallet" },
    { name: "Karton", _id: "box" },
  ];

  // useEffect(() => {
  //   (async () => {
  //     const products = await productsService.getProductsList();
  //     const filteredProducts = products.filter(p => p.active);
  //     const productsList = filteredProducts.map(p => ({
  //       name: `${p.name} - ${p.sku}`,
  //       value: p.sku
  //     }));
  //     setProductOptions(productsList);
  //   })();
  // }, [isOpen]);

  const handleHide = async () => {
    setErrorMessage("");
    onResolve();
  };

  const handleSubmit = async () => {
    //Remove rich data from connected Locations on Submit
    const requestData = _.cloneDeep(data);

    requestData.connectedLocations = requestData.connectedLocations.map(c => String(c.locationId));

    if (requestData.contents && requestData.contents.length) {
      requestData.skus = requestData.contents.map(p => String(p.product.sku));
    }

    if (requestData.rack) requestData.rack = String(requestData.rack);
    if (requestData.level) requestData.level = String(requestData.level);
    if (requestData.position) requestData.position = String(requestData.position);

    delete requestData.contents;
    delete requestData.sku;

    const result = await warehouseService.updateLocationSettings(location.locationId, requestData, setErrorMessage);
    if (!result) return;
    await onReload();
    onResolve();
  };

  const handleChangeKind = ({ currentTarget }) => {
    const requestData = _.cloneDeep(data);
    if (currentTarget.value === "pickingSpot") {
      requestData.pickingSpot = true;
      requestData.storageCategory = "single";
    } else if (currentTarget.value === "pickingMultiple") {
      requestData.pickingSpot = true;
      requestData.storageCategory = "single_multiple";
    } else if (currentTarget.value === "storage") {
      requestData.pickingSpot = false;
      requestData.storageCategory = "pallet";
      delete requestData.sku;
    } else if (currentTarget.value === "storageMultiple") {
      requestData.pickingSpot = false;
      requestData.storageCategory = "pallet_multiple";
      delete requestData.sku;
    }
    setData(requestData);
  };

  const handleChangeCategory = ({ currentTarget }) => {
    const requestData = _.cloneDeep(data);
    requestData.storageCategory = currentTarget.value;
    setData(requestData);
  };

  // const handleChangeProduct = sku => {
  //   const requestData = _.cloneDeep(data);
  //   requestData.sku = sku;
  //   requestData.storageCategory = "single";
  //   requestData.pickingSpot = true;
  //   setData(requestData);
  // };

  const handleDelete = async connectedLocation => {
    const requestData = _.cloneDeep(data);
    const testData = _.cloneDeep(data);
    testData.skus = testData.contents ? testData.contents.map(p => p.product.sku) : [];
    testData.connectedLocations = testData.connectedLocations.map(c => c.locationId);
    delete testData.contents;

    const result = await showConfirmModal({
      title: "Verknüpfung aufheben?",
      confirmText: "Verknüpfung aufheben",
      description: `Soll die Verknüpfung zu ${warehouseService.getLocationNumber(
        connectedLocation
      )} wirklich aufgehoben werden?`,
    });
    if (!result) return;

    //Test deletion
    const filteredConnectedLocations = data.connectedLocations.filter(
      l => connectedLocation.locationId !== l.locationId
    );
    testData.connectedLocations = filteredConnectedLocations.map(c => c.locationId);
    const testResult = await warehouseService.testChangeWarehouseLocation(location.locationId, testData);
    if (testResult !== "Erfolg") return;

    //Test succeded
    requestData.connectedLocations = data.connectedLocations.filter(l => connectedLocation.locationId !== l.locationId);
    setData(requestData);
  };

  const handleAdd = async () => {
    const requestData = _.cloneDeep(data);
    const testData = _.cloneDeep(data);
    testData.skus = testData.contents ? testData.contents.map(p => p.product.sku) : [];
    testData.connectedLocations = testData.connectedLocations.map(c => c.locationId);
    delete testData.contents;

    const result = await showAddLocationModal();
    if (!result || result.type !== "location") return;
    try {
      testData.connectedLocations.push(result.data);
      const testResult = await warehouseService.testChangeWarehouseLocation(location.locationId, testData);
      if (testResult !== "Erfolg") return;

      //Test is successful, continue.
      const locObj = await warehouseService.loadWarehouseLocation(result.data);
      if (!locObj) return;

      requestData.connectedLocations.push(locObj);
      setData(requestData);
    } catch (err) {
      toast.error("Der Lagerplatz konnte nicht hinzugefügt werden.");
      logger.error("Error loading the warehouse location:", err);
    }
  };

  const handleDeleteProduct = async connectedProduct => {
    const requestData = _.cloneDeep(data);
    const testData = _.cloneDeep(data);
    testData.skus = testData.contents ? testData.contents.map(p => p.product.sku) : [];
    delete testData.contents;
    testData.connectedLocations = testData.connectedLocations.map(c => c.locationId);

    const result = await showConfirmModal({
      title: "Verknüpfung aufheben?",
      confirmText: "Verknüpfung aufheben",
      description: `Soll die Verknüpfung zu ${
        connectedProduct.product.name + " - " + connectedProduct.product.sku || connectedProduct.product.sku
      } wirklich aufgehoben werden?`,
    });
    if (!result) return;
    //Test deletion
    const filteredConnectedProducts = data.contents.filter(p => connectedProduct.product.sku !== p.product.sku);
    testData.skus = filteredConnectedProducts.map(c => c.product.sku);
    const testResult = await warehouseService.testChangeWarehouseLocation(location.locationId, testData);
    if (testResult !== "Erfolg") return;

    //Test succeded
    requestData.contents = filteredConnectedProducts;
    setData(requestData);
  };

  const handleAddProduct = async () => {
    const requestData = _.cloneDeep(data);
    const testData = _.cloneDeep(data);
    testData.skus = testData.contents ? testData.contents.map(p => p.product.sku) : [];
    delete testData.contents;
    testData.connectedLocations = testData.connectedLocations.map(c => c.locationId);
    delete requestData.sku;
    delete testData.sku;

    const result = await showAddProductModal();
    if (!result || result.type !== "product") return;
    try {
      const prodObj = await warehouseService.loadProduct(result.data);
      if (!prodObj) return;

      testData.skus.push(prodObj.sku);
      const testResult = await warehouseService.testChangeWarehouseLocation(location.locationId, testData);
      if (testResult !== "Erfolg") return;

      //Test is successful, continue.
      if (requestData.contents) {
        if (!requestData.contents.find(p => p.product.sku === prodObj.sku))
          requestData.contents.push({ product: prodObj, quantity: 0 });
      } else requestData.contents = [{ product: prodObj, quantity: 0 }];
      setData(requestData);
    } catch (err) {
      toast.error("Das Produkt konnte nicht hinzugefügt werden.");
      logger.error("Error loading the product:", err);
    }
  };

  return (
    <Fragment>
      <Modal
        show={isOpen}
        size="lg"
        onHide={() => onResolve()}
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className=""
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {`Lagerplatz ${warehouseService.getLocationNumber(location)} bearbeiten`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {spinner ? (
            <div className="m-5 text-center">
              <Loader className="mb-2" />
            </div>
          ) : (
            <Fragment>
              <div>
                {description ? <p>{description}</p> : ""}
                <div className="mb-2">
                  <Select
                    name="kind"
                    label="Lagerplatz Art"
                    options={options}
                    value={
                      data.pickingSpot
                        ? data.storageCategory === "single"
                          ? "pickingSpot"
                          : "pickingMultiple"
                        : data.storageCategory === "pallet_multiple"
                        ? "storageMultiple"
                        : "storage"
                    }
                    onChange={handleChangeKind}
                  />
                </div>
                {!data.pickingSpot ? (
                  <div className="mb-2">
                    <Select
                      name="category"
                      label="Lagerplatz Kategorie"
                      options={
                        data.storageCategory === "pallet_multiple"
                          ? [{ name: "Palette", _id: "pallet" }]
                          : categoryOptions
                      }
                      value={data.storageCategory}
                      onChange={handleChangeCategory}
                    />
                  </div>
                ) : (
                  <div className="mb-2">
                    <p className="mb-2">
                      {data.storageCategory === "single_multiple" ? "Zugeordnete Produkte" : "Zugeordnetes Produkt"}
                    </p>
                    <div className="form-row">
                      <div className="col">
                        <div className="input-style d-flex flex-wrap">
                          {data.contents && data.contents.length ? (
                            data.contents.map(p => (
                              <h5 className="mb-1 mt-0 mr-1" onClick={() => handleDeleteProduct(p)}>
                                <span className="badge badge-success text-left">
                                  <div className="form-row">
                                    <div className="col">
                                      <span className="badge-break-nopad">
                                        {p.product.name + " - " + p.product.sku || p.product.sku}
                                      </span>
                                    </div>
                                    <div className="col-auto">
                                      <i className="fas fa-times-circle ml-2 my-1"></i>
                                    </div>
                                  </div>
                                </span>
                              </h5>
                            ))
                          ) : (
                            <span className="text-light-gray ml-1 mb-1">
                              {data.storageCategory === "single_multiple"
                                ? 'Wähle "+" um Produkte zu hinzuzufügen'
                                : 'Wähle "+" um ein Produkt zu hinterlegen'}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-auto">
                        <Button variant="secondary" onClick={handleAddProduct}>
                          <i className="fas fa-plus-circle"></i>
                          {isSafeToClick(true)}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  <p className="mb-2">Verknüpfte Lagerplätze</p>
                  <div className="form-row">
                    <div className="col">
                      <div className="input-style d-flex flex-wrap">
                        {data.connectedLocations && data.connectedLocations.length ? (
                          data.connectedLocations.map(l => (
                            <h5 className="mb-1 mt-0 mr-1" onClick={() => handleDelete(l)}>
                              <span className="badge badge-secondary">
                                <span>{warehouseService.getLocationNumber(l)}</span>
                                <i className="fas fa-times-circle ml-2 my-1"></i>
                              </span>
                            </h5>
                          ))
                        ) : (
                          <span className="text-light-gray ml-1 mb-1">Wähle "+" um Lagerplätze zu verknüpfen</span>
                        )}
                      </div>
                    </div>
                    <div className="col-auto">
                      <Button variant="secondary" onClick={handleAdd}>
                        <i className="fas fa-plus-circle"></i>
                        {isSafeToClick(true)}
                      </Button>
                    </div>
                  </div>
                </div>
                {errorMessage ? <div className="alert alert-danger">{errorMessage}</div> : ""}
              </div>
            </Fragment>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleHide}>
            {cancelText || "Abbrechen"}
          </Button>
          <Button variant={buttonColor ? buttonColor : "primary"} onClick={handleSubmit}>
            {confirmText || "Ja"}
            {isSafeToClick(true)}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default LocationSettings;
